// Login and Logout Pages

.logout-page {

    .page-message {
        margin: 60px auto;
        padding: 15px;
        background-color: #eee;
        text-align: center;
        max-width:700px;

        p {
            font-size: 1.3rem;
            margin: 0;
        }
        
    }
}

.login-page {

    section {
        margin: 0;
        max-width: 300px;
        margin:auto;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: underline;
        }
    }

    .heading {
        margin-bottom: 30px;
    }
    

    @media (min-width: 768px) {
        .members-col {
            border-right: 1px solid #3d5567;
        }
    }
}


// Manage account page
.manage-account-page {

    .page-header {
        flex-direction: column;
        align-items: flex-start;
    }
    
    h4 {
        font-size: 1.4rem;
        margin-top: 15px;
        color: $brand-blue;
    }
    
    .col-one {
        padding: 0;
        margin-bottom: 15px;
    }

    .col-two {

    }

    .left-nav {
        background-color: #f4f4f4;
        border-top: 3px solid $blue;
        margin-bottom: 15px;
        height: 100%;

        nav {
            height: 100%;
        }

        .nav-item {

            .nav-link {
                background-color: transparent;
                color: #000;

                &.active {
                    background-color: #fff;
                    color: $blue;
                }

                &:hover:not(.active) {
                    color: #666;
                }
            }
        }
        
    }

    @media (min-width:768px) {

        .col-one {
            padding-right: 15px;
            margin-bottom: 0;
        }
        
        .left-nav {
            margin-bottom: 0;
        }
    }
}