// Users page

.users-page {

    @media (min-width: 768px) {
        .table-block {
            padding: 30px 40px;
        }
    }
        
}