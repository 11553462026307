// Survey page
.survey-page {

    &.survey {

        .sv_container {
            .sv_header {
                background-color: #fff;
                padding: 15px 0 30px;
    
                .sv_header__text {
                    
        
                    h3 {
                        font-family:Lato-Bold,sans-serif;
                        font-size: 2.5rem;
                        color: $brand-blue;
                    }   
        
                    h4, h5 {
                        font-size: 1.2rem;
                        color: #666;
                        margin-top: 10px;
                        font-weight: 500;
                    }
                }
    
            }

            .sv_body {
                padding: 1em 0 1.6em 0;
                border: 0;

                .sv_p_root {
                    .sv_q {
                        padding: 0.5em 0 1.5em 0;
                    }
                }
            }

        }

        .footer-block {
            display: flex;
            flex-direction: column;
            margin-bottom: .25rem;
            font-size: .9rem;
    
            @media (min-width:762px) {
                flex-direction: row;
                justify-content: space-between;
            }
        }

    }

}

.sv_main .sv_container .sv_header h3 {
    font-weight: 600!important;
    color: #3d5567;
}

.sv_main .sv_container .sv_header h5 {
    font-weight: 400;
    color: #3d5567;
}


