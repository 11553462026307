// Page

.page-header {
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0 15px;

    h1 {
        margin: 0;
        padding: 0;
    }

    .page-header-content {
        background-color: #fff;
        margin: 0;
        max-width: 100%;
        width: auto;
        padding: 0;
    }
}

.historyToggleContainer {
    padding-top: 6px;
    margin: 0 auto;
    width: 150px;


    .historyToggle {
        width: 60px;
        display: inline-block;
        text-align: center;
        cursor: pointer;
    }
}

.svd_container {
    .svd-tab.active > .svd-tab-text {
        background-color: #fff!important;
    }
} 



.sv_body {
    overflow: auto;
    background-color: $grey-light;
    padding: 15px;
}


@media (min-width: 576px) {
        
}

@media (min-width: 768px) {
    .page-header {
        padding: 30px 40px;
    }
}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {

}

