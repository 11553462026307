// Theme Stylesheet
// =============================================================================


// Brand colours
$brand-orange:                #E65300;
$brand-blue:                  #3D5567;

$blue:                        #00ACD7;
$aqua:                        #2CABB7;
$red:                         #F21D0C;
$yelllow:                     #F5A800;
$green:                       #8F993E;

$brand-primary:                 $brand-orange;
$brand-secondary:               $brand-blue;

$primary:                       $brand-primary;
$secondary:                     $brand-secondary;
$danger:                        $red;
$info:                          $blue;
$success:                       $green;

$grey-light:                  #f4f4f4;


// Fonts ---

$fontPath: "../fonts/";

@font-face {
    font-family: 'Lato-Black';
    src:  url($fontPath+'Lato-Black.ttf') format('truetype');
}
@font-face {
    font-family: 'Lato-BlackItalic';
    src:  url($fontPath+'Lato-BlackItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'Lato-Bold';
    src:  url($fontPath+'Lato-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'Lato-BoldItalic';
    src:  url($fontPath+'Lato-BoldItalic.ttf') format('truetype');
}
@font-face {
    font-family: 'Lato-Italic';
    src:  url($fontPath+'Lato-Italic.ttf') format('truetype');
}
@font-face {
    font-family: 'Lato-LightItalic';
    src:  url($fontPath+'.ttf') format('truetype');
}
@font-face {
    font-family: 'Lato-Regular';
    src:  url($fontPath+'Lato-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Lato-Thin';
    src:  url($fontPath+'Lato-Thin.ttf') format('truetype');
}
@font-face {
    font-family: 'Lato-ThinItalic';
    src:  url($fontPath+'Lato-ThinItalic.ttf') format('truetype');
}


$default-font: 'Lato-Regular', sans-serif;

//Globals
body {
    background-color: #fff; 
    overflow-x: hidden;
}

main {
    overflow-x: hidden;
}

a {
color: $aqua;
transition: color .3s ease-in-out;
text-decoration: none;

&:hover {
    color: darken($aqua, 10%);
    text-decoration: none;
}
}


//Headings
h1 {
    font-family: 'Lato-Bold', sans-serif;
    color: $secondary;
    overflow-x: hidden;
    font-size: 1.85rem;
}

h4 {
    font-size: 1.4rem;
    margin-top: 15px;
    color: $brand-blue;
}

.fluid-layout-padding {
    @media (min-width: 768px) {
        padding: 15px 40px;
    }
}


//Buttons
.btn {
    outline: none;
    transition: all .3s ease-in-out;
    text-transform: uppercase;
    padding: .25rem 2.4rem;
    cursor: pointer!important;
    box-shadow: none;
    border: 0;
    text-decoration: none;
    font-weight: 500;
    font-size: .9rem;
    margin: .1rem .2rem;
    min-width: 220px;
    
    &:first-of-type {
        margin-left: 0;
    }
    &:last-of-type {
        margin-right: 0;
    }

    &:hover, &:focus, &:active {
        outline: none;
        border: 0;
        text-decoration: none;
        box-shadow: none!important;
    }

    &.btn-primary,
    &.btn-primary:active  {
        background-color: $primary;
        color: #fff;

        &:hover {
            background-color: darken($primary, 10%);
        }
    }

    &.btn-danger, 
    &.btn-danger:active  {
        background-color: $danger;
        color: #fff;

        &:hover {
            background-color: darken($danger, 10%);
        }
    }   

    &.btn-secondary,
    &.btn-secondary:active  {
        background-color: $secondary;
        color: #fff!important;

        &:hover {
            background-color: darken($secondary, 10%);
        }
    }

    &.btn-info,
    &.btn-info:active  {
        background-color: $blue;
        color: #fff!important;

        &:hover {
            background-color: darken($blue, 10%);
        }
    }
}


//Tables

table {

    thead {
        th {
            border-top: 0!important;
            border-bottom: 0!important;
            color: #666;
            font-size: .85rem;
        }
    }


    tbody {
        tr {
            &:nth-child(odd) {
                background: #efefef;
            }
            &:nth-child(even) {
                background: #FFF
            }
        }
    }
}

//Statuses
.status {
    &.NotStarted {
        .marker, .btn-info {
            background: $red;
        }

        i {
            color: $red;
        }
    }

    &.InProgress {
        .marker, .btn-info {
            background: $yelllow;
        }

        i {
            color: $yelllow;
        }
    }

    &.Completed {
        .marker, .btn-info {
            background: $green;
        }

        i {
            color: $green;
        }
    }

    &.Approved {
        .marker, .btn-info, .btn-success {
            background: $blue;
        }

        i {
            color: $blue;
        }
    }

    &.NotApproved {
        .marker {
            background: $yelllow;
        }

        i {
            color: $yelllow;
        }
    }

    &.Closed {
        .marker {
            background: $blue;
        }

        i {
            color: $blue;
        }
    }

    i {
        margin-right: 8px;
    }
}