/* Please see documentation at https://docs.microsoft.com/aspnet/core/client-side/bundling-and-minification
for details on configuring this project to bundle and minify static web assets. */
/* Provide sufficient contrast against white background */
/* Sticky footer styles
-------------------------------------------------- */
/* Sticky footer styles
-------------------------------------------------- */
a.navbar-brand {
	white-space: normal;
	text-align: center;
	word-break: break-all;
}
a {
	color: #0366d6;
}
.btn-primary {
	color: #fff;
	background-color: #1b6ec2;
	border-color: #1861ac;
}
.nav-pills {
	.nav-link.active {
		color: #fff;
		background-color: #1b6ec2;
		border-color: #1861ac;
	}
	.show {
		>.nav-link {
			color: #fff;
			background-color: #1b6ec2;
			border-color: #1861ac;
		}
	}
}
html {
	font-size: 14px;
	position: relative;
	min-height: 100%;
}
.border-top {
	border-top: 1px solid #e5e5e5;
}
.border-bottom {
	border-bottom: 1px solid #e5e5e5;
}
.box-shadow {
	box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
button.accept-policy {
	font-size: 1rem;
	line-height: inherit;
}
body {
	margin-bottom: 60px;
	margin: 0;
	padding: 0;
	background-color: #f4f4f4;
}
.footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	white-space: nowrap;
	line-height: 60px;
}
.logo {
	width: 170px;
	height: 60px;
	background-size: 170px 60px;
	background-image: url(../images//logo.svg);
}
.header {
	position: relative;
	z-index: 999;
	background-color: #3d5567;
}
section {
	margin: 2em 0;
}
.page-link {
	margin: 1em 2em;
}
.survey-page-header {
	background-color: #e7e7e7;
	.sv_main {
		background-color: #e7e7e7;
	}
}
.survey-page-header-content {
	max-width: 80%;
	margin: auto;
	padding: 1.5em;
	a {
		color: white;
		text-decoration: unset;
	}
}
.sv_main.sv_frame {
	.sv_container {
		.sv_header {
			padding-top: 2em;
		}
	}
	.svd_container {
		.sv_container {
			max-width: none;
			padding: unset;
		}
	}
}
.svd_container {
	.sv_custom_header {
		display: none;
	}
}
.surveys-list {
	table {
		thead {
			td {
				font-weight: bold;
			}
		}
		td {
			&:nth-child(2) {
				width: 50%;
			}
		}
	}
}
.sv_button_link {
	color: #1ab394;
	width: 100px;
	display: inline-block;
	padding: 6px 12px;
	cursor: pointer;
	text-align: center;
	&:hover {
		background-color: #1ab394;
		border-color: #1ab394;
		color: #fff;
		border-radius: 2px;
		text-decoration: none;
	}
	&:visited {
		&:hover {
			background-color: #1ab394;
			border-color: #1ab394;
			color: #fff;
			border-radius: 2px;
			text-decoration: none;
		}
		color: #1ab394;
	}
}
.sv_button_link.sv_button_delete {
	color: #ec2626;
	&:hover {
		background-color: #ec2626;
		color: #fff;
	}
}
#resultsTable {
	td {
		cursor: pointer;
	}
}
.sv_body {
	overflow: scroll;
}
.fork_me_on_github {
	img {
		z-index: 1;
	}
}

// #surveyPrevious {
// 	color: #ffffff;
// 	background-color: #3d5567;
// 	text-align: center;
// 	width: 100px;
// }
// #surveyNext {
// 	color: #ffffff;
// 	background-color: #3d5567;
// 	text-align: center;
// 	width: 100px;
// }
// #surveySubmit {
// 	color: #ffffff;
// 	background-color: #3d5567;
// 	text-align: center;
// 	width: 100px;
// }
// #surveySave {
// 	color: #ffffff;
// 	background-color: #3d5567;
// 	text-align: center;
// 	width: 100px;
// }
@media (min-width: 768px) {
	html {
		font-size: 16px;
	}
}

.validation-summary-valid {
	display: none;
}

.validation-summary-errors {
	ul {
		list-style-type: none;
		padding-inline-start: 0px;
		margin-bottom: 0px;
	}
}