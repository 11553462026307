header {
    background-color: #3d5567;

    .top-row {
        display: flex;
        justify-content: space-between; 
        flex-direction: row;
        width: 100%;
        padding: 15px;
    }

    .navbar {
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;    

        &:before, &:after {
            content: unset;
            display: none;
        }
    }

    a{
        text-decoration: none;
    }
    
    .navbar-nav {
        display: flex;
        justify-content: flex-end;
        margin: 0;
        width: 100%;

        &.flex-grow-1 {
            justify-content: flex-start;
        }

        .nav-item {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .nav-link {
                padding-right: 15px;
            }

            .nav-link, .logout-link {
                color: #fff;
                transition: color .3s ease-in-out;

                &:hover, &:focus {
                    text-decoration: none;
                    outline: 0;
                }

            }

            .logout-link, .login-link {
                outline: 0;
                padding-right: 0;
                font-weight: 400;
                text-transform: none;

                i {
                    margin-right: 5px;
                }
            }
        }
    }

    .navbar-collapse {
        box-shadow: none;
        margin-bottom: 5px;
        padding: 0 15px 5px;
    }
    

    .logo {
        display: block;
        width: 120px;
        height: 42px;
        background-size: contain;
        background-repeat: no-repeat;
    }

    @media (min-width: 576px) {
        .top-row {
            width: auto;
        }

        .navbar-collapse {
            padding: 0;
        }

        .logo {
            background-size: contain;
            background-repeat: no-repeat;
        }

        .nav-block {
            width: 100%;
        }

        .navbar-nav {
            &:first-of-type {
                order: 2;
            }
        }
    }
   

    @media (min-width: 768px) {

        > .navbar {
            padding: 15px 50px;
        }

        .top-row {
            padding: 5px 0;
        }

        .navbar-collapse.collapse {
            display: flex!important;
        }

        .logo {
            width: 160px;
            height: 56px;
            background-size: contain;
            background-repeat: no-repeat;
        }

        .nav-block {
            margin-left: auto;
            padding: 0 0 0 60px;
        }
        

        .navbar-nav {
            &:first-of-type {
                order: 2;
            }
        }
    }

    @media (min-wdith: 992px) {
        
    }

    @media (min-wdith: 1200px) {
        
    }
}